

































































































import { Component, Prop, Vue } from "vue-property-decorator";
// import useRef from "Vue";
import Layout from "@/components/Layout.vue"; // @ is an alias to /src
import AssignCard from "@/components/common/AssignCard.vue";
import MainSlogan from "@/components/common/MainSlogan.vue";
import WordOnPic from "@/components/common/WordOnPic.vue";
import PicWall from "@/components/common/PicWall.vue";
import Applyin from "@/components/common/Applyin.vue";
@Component({
  components: {
    Layout,
    AssignCard,
    MainSlogan,
    WordOnPic,
    PicWall,
    Applyin,
  },
})
export default class Storage extends Vue {
  bg = require("@/assets/storage/img/storagebg.png");
  stwo = require("@/assets/storage/img/stwo.png");
  jiatingcuneng = require("@/assets/storage/img/jiatingcuneng.png");
  shangyechuneng = require("@/assets/storage/img/shangyechuneng.png");
  gonggongjigou = require("@/assets/storage/img/gonggongjigou.png");
  gongyechuneng = require("@/assets/storage/img/gongyechuneng.png");
  yidongchuneng = require("@/assets/storage/img/yidongchuneng.png");
  arrawblack: string = require("@/assets/home/img/arrawblack.png");

  mobileArea = [
    {
      id: 1,
      image: this.jiatingcuneng,
      title: "家庭能源",
      ref: "one",
      des: [
        "完美契合家庭充放电场景，白天光伏发电供家庭自用，余电储存至储能系统，晚上释放储能中的电能供家庭和电动汽车使用，环比省钱还能参与绿电收益。",
        "安装储能设备，实现7/24电力覆盖，按需设定储备电力数额，错峰使用电力，省钱省心。",
        "外出度假时，调整储备电力数额，将所发电量全额并入电网，收益与效率并存。",
      ],
    },
    {
      id: 2,
      image: this.shangyechuneng,
      title: "商业储能",
      ref: "two",
      des: [
        "通过安装商业楼宇储能，增强电网运行的独立性与稳定性，可以实现能量挪移，应用合理高效的储能系统可促进可再生能源的生存和发展。",
        "楼宇用能高、发电量大，安装储能柜可实现电网稳定运行。并通过‘移峰填谷’的方式，在高电价时间段使用光伏系统所发电力，低电价时间段使用储能柜供楼宇用电，大幅度减少楼宇运营者的用电成本，进一步减少城市能耗。停电时，储能柜释放电能轻松应对断电状况。",
      ],
    },
    {
      id: 3,
      image: this.gonggongjigou,
      title: "公用机构储能",
      ref: "three",
      des: [
        "公用机构接入储能系统，通过光储配合，实现自身的节能减碳目的，高效消纳利用。公用机构的的储能应用对地区促进经济社会发展全面绿色转型、实现生态环境质量改善，起到示范引领作用。",
      ],
    },
    {
      id: 4,
      image: this.gongyechuneng,
      title: "工业储能",
      ref: "four",
      des: [
        "储能的运营有效保障园区数据中心、办公用电等重要负荷的不间断供电，提升园区用电的可靠性。",
        "园区内机器用电负荷高，节约电费需求强烈。在园区内嵌入储能柜可解决“能源存储”问题，白天利用光伏系统发电，晚上利用储能系统放电，园区内完全实现清洁能源电力的自给自足，减少用电成本，有效降低企业经营成本。",
      ],
    },
  ];
  isTabFix = false;
  prevScroll = 0;
  isActive = 1;
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  }
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  judgeIsShow(el: any) {
    let docEl = document.documentElement;
    let oneRect = el!.getBoundingClientRect();
    return (
      oneRect.top >= (window.innerHeight || docEl.clientHeight) ||
      oneRect.bottom <= 0
    );
  }
  handleScroll() {
    let scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop;
    let docEl = document.documentElement;
    let width = docEl.getBoundingClientRect().width;
    let judgeOne = this.judgeIsShow(document.querySelector("#one")),
      judgeTwo = this.judgeIsShow(document.querySelector("#two")),
      judgeThree = this.judgeIsShow(document.querySelector("#three")),
      judgefour = this.judgeIsShow(document.querySelector("#four"));
    if (!judgeOne) {
      this.isActive = 1;
    } else if (!judgeTwo) {
      this.isActive = 2;
    } else if (!judgeThree) {
      this.isActive = 3;
    } else if (!judgefour) {
      this.isActive = 4;
    }
    let $rem = width / 375;
    if (scrollTop > 390 * $rem) {
      // console.log("scrollTop", scrollTop);
      this.isTabFix = true;
    } else {
      this.isTabFix = false;
    }
  }
  goForward() {
    console.log(1);

    this.$router.push({
      path: "/clean/homeenergy",
    });
  }
}
